<template>
    <acs-base-container large size="large">
        <v-card flat v-if="products.length">
            <v-form v-model="valid" @submit.stop.prevent="validateCart" :disabled="loading">
                <acs-product-translate
                    class="mx-3"
                    v-model="translate"
                    :loading="loading"
                    @change="load(true)" />

                <div class="d-flex justify-end mb-3">
                    <acs-cart-scan-product-btn @scan="ev => onScan(ev.product_id)" :loading="loading" />
                </div>

                <acs-cart-error :errors="cartErrors" />

                <v-divider class="mx-3" />

                <div v-for="cartproduct in products" :key="cartproduct.id">
                    <acs-cart-product
                        :slug="slug"
                        :cartproduct="cartproduct"
                        :quantity.sync="cartproduct.quantity"
                        :translate="translate"
                        :error="errors.find(err => err.field === cartproduct.product.id)" />

                    <v-divider class="mx-3" />
                </div>

                <v-card-actions>
                    <v-btn
                        block
                        outlined
                        color="primary"
                        class="mb-3"
                        rounded
                        :to="{ name: getContextRouteName('menulist') }">
                        {{ $t('cart.productAdd') }}
                    </v-btn>
                </v-card-actions>

                <acs-cart-total />

                <acs-cart-service-selector
                    v-model="cart.service_id"
                    :translate="translate"
                    class="mx-4"
                    :slug="slug" />

                <acs-cart-order-mode-selector
                    v-model="cart.order_mode"
                    :slug="slug"
                    :service-id="cart.service_id"
                    required
                    class="mx-4" />

                <acs-cart-message v-model="cart.message" class="px-4" />

                <v-card-actions>
                    <v-btn
                        class="primary"
                        block
                        rounded
                        type="submit"
                        :disabled="!valid"
                        :loading="loading">
                        {{ $t('cart.submit') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <v-card flat v-else :loading="loading">
            <img class="acs-call--center" :src="`/img/cart-empty.svg`" />
            <v-card-title class="justify-center">
                <div>{{ $t('cart.empty') }}</div>
            </v-card-title>
            <v-card-text class="px-2">
                <div class="d-flex justify-center mb-5">
                    <acs-cart-scan-product-btn @scan="ev => onScan(ev.product_id)" :loading="loading" />
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    block
                    outlined
                    color="primary"
                    rounded
                    :to="{ name: getContextRouteName('menulist') }">
                    {{ $t('cart.back') }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <template v-if="scannedProduct">
            <acs-cart-service-popup
                v-model="showServicePopup"
                :slug="slug"
                :translate="translate"
                :service-ids="scannedProduct.services"
                @selected="onServiceSelected" />

            <acs-cart-scan-product-option-popup
                v-model="showCartScanProductOptionPopup"
                :product="scannedProduct"
                @click:add-to-cart="addProductWithOption" />
        </template>

    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsCartError from '@/components/cart/AcsCartError'
import AcsCartProduct from '@/components/cart/AcsCartProduct'
import AcsCartTotal from '@/components/cart/AcsCartTotal'
import AcsCartServiceSelector from '@/components/cart/AcsCartServiceSelector'
import AcsCartMessage from '@/components/cart/AcsCartMessage'
import AcsCartOrderModeSelector from '@/components/cart/AcsCartOrderModeSelector'
import AcsProductTranslate from '@/components/product-list/AcsProductTranslate'
import AcsCartServicePopup from '@/components/cart/AcsCartServicePopup'
import AcsCartScanProductBtn from '@/components/cart/AcsCartScanProductBtn'
import AcsCartScanProductOptionPopup from '@/components/cart/AcsCartScanProductOptionPopup'

import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'cart',
    components: {
        AcsBaseContainer,
        AcsCartError,
        AcsCartProduct,
        AcsCartTotal,
        AcsCartMessage,
        AcsCartServiceSelector,
        AcsCartOrderModeSelector,
        AcsProductTranslate,
        AcsCartServicePopup,
        AcsCartScanProductBtn,
        AcsCartScanProductOptionPopup
    },
    props: {
        slug: { type: String, required: true },
        aslot: { type: String, required: false }
    },
    mixins: [RouteMixin],
    data: () => ({
        valid: false,
        loading: false,
        translate: false,
        showServicePopup: false,
        scannedProduct: null,
        showCartScanProductOptionPopup: false,
        errors: []
    }),
    computed: {
        products() {
            return this.$store.getters['cart/products']
        },
        cart() {
            return this.$store.getters['cart/cart'] || {}
        },
        cartErrors() {
            const ids = this.products.map(cartproduct => cartproduct.product.id)
            return this.errors.filter(err => !ids.includes(err.field))
        },
        service() {
            return this.$store.getters['ser/services'].find(s => s.id === this.cart.service_id)
        },
        selectedTimeframes() {
            return this.$store.getters['cart/cart']?.timeframes || []
        },
        filters() {
            return this.$store.getters['prod/filters'](this.slug)
        }
    },
    watch: {
        'products.length': 'onProductRemove',
        selectedTimeframes() {
            this.errors = []
        }
    },
    async mounted() {
        await this.load()

        // s'il y avait un reste de localstorage avec ID de panier,
        // on l'efface
        this.$store.commit('cart/setCurrentCartId', { id: null })

        if (this.$route.query.scanpid) {
            return this.onScan(this.$route.query.scanpid)
        }
    },
    methods: {
        load(translate) {
            this.loading = true
            return Promise
                .all([
                    this.$store.dispatch('cart/get', {
                        slug: this.slug,
                        translate: translate
                    }),
                    this.$store.dispatch('sp/current', { slug: this.slug }),
                    this.aslot && this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
                ])
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        onProductRemove() {
            if (this.products.length) {
                return
            }
            // si plus aucun produit n'existe, on supprime le panier sur le serveur
            this.loading = true
            return this.$store.dispatch('cart/remove', { slug: this.slug })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        async validateCart() {
            this.loading = false
            this.errors = []
            try {
                await this.$store.dispatch('cart/update', { ...this.cart, slug: this.slug, slot_slug: this.aslot })
                await this.$store.dispatch('cart/validate', { slug: this.slug })
                    .catch(err => {
                        if (err?.response?.status === 422) {
                            this.errors = err.response.data?.errors || []
                            // si l'une des erreurs est les créneaux manquants, on affiche la popup
                            if (this.errors.find(e => e.type === 'timeframemissing')) {
                                this.$store.commit('ser/timeframesPopup', { show: true })
                            }
                        } else {
                            throw err
                        }
                    })

                if (!this.errors.length) {
                    // check s'il y a des données avancées à saisir
                    const advanced = await this.$store.dispatch('ser/advanced', {
                        slug: this.slug,
                        service_id: this.cart.service_id
                    })
                    const showAdvanced = advanced && (advanced.price || advanced.options?.length)
                    const showTimeframe = this.service?.timeframe?.options?.length
                    this.$router.push({ name: this.getContextRouteName(showAdvanced || showTimeframe ? 'cartadvanced' : 'cartpay') })
                }
                this.loading = false
            } catch (err) {
                this.loading = false
                this.$err(err)
            }
        },

        addProduct() {
            return this.$store
                .dispatch('cart/addProduct', {
                    slug: this.slug,
                    product_id: this.scannedProduct.id,
                    quantity: 1,
                    options: []
                })
                .then(res => {
                    this.scannedProduct = null
                    this.showServicePopup = false

                    return this.$success(res, {
                        message: 'cart.productAdded'
                    })
                })
        },

        onServiceSelected(serviceId) {
            if (!serviceId) {
                return
            }
            if (this.$store.getters['ser/services'].find(s => s.id === serviceId).timeframe) {
                this.$store.commit('ser/timeframesPopup', {
                    show: true,
                    onSelectedFn: () => this.addProduct()
                })
                return
            }

            this.loading = true
            return this.$store.dispatch('cart/update', { createIfNotExists: true, slug: this.slug, service_id: serviceId })
                .then(() => this.addProduct())
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        addProductWithOption({ product }) {
            return this.onScan(product.id, { skipOptionTest: true })
        },

        onScan(productId, { skipOptionTest } = {}) {
            this.loading = true
            this.showCartScanProductOptionPopup = false
            return this.$store.dispatch('prod/getProduct', { slug: this.slug, product_id: productId })
                .then(async product => {
                    if (await this.$store.dispatch('prod/hasRequiredOptions', { product })) {
                        // si l'article a des options obligatoires, on redirige vers le formulaire
                        // car on ne peut pas décider à la place des gens ce qu'ils veulent
                        await this.$router.push({
                            name: this.getContextRouteName('productform'),
                            params: {
                                category: product.category_id,
                                product: product.id
                            },
                            query: {
                                back: this.$route.path
                            }
                        })
                        return false
                    }

                    this.scannedProduct = product

                    if (!skipOptionTest && product.options?.length) {
                        this.showCartScanProductOptionPopup = true
                        return false
                    }

                    if (this.products.length || this.service) {
                        return true
                    }

                    const manageServiceTimeframesPopup = serviceId => {
                        if (this.$store.getters['ser/services'].find(s => s.id === serviceId).timeframe) {
                            this.$store.commit('ser/timeframesPopup', {
                                show: true,
                                onSelectedFn: () => this.addProduct()
                            })
                            return false
                        }
                        return true
                    }

                    // Ajout du 1er produit, on va d'abord récupérer le service pertinent
                    if (product.services.length === 1) {
                        // on a un seul service disponible, on filtre dessus
                        await Promise.all([
                            this.$store.dispatch('cart/update', {
                                createIfNotExists: true,
                                slug: this.slug,
                                service_id: product.services[0]
                            }),
                            this.$store.dispatch('prod/mergeFilters', {
                                slug: this.slug,
                                service: product.services[0]
                            })
                        ])
                        return manageServiceTimeframesPopup(product.services[0])
                    }
                    if (this.filters.service) {
                        // un filtre existe sur les services, on set le
                        // service sélectionné dans le panier
                        await this.$store.dispatch('cart/update', {
                            slug: this.slug,
                            service_id: this.filters.service
                        })
                        return manageServiceTimeframesPopup(this.filters.service)
                    }

                    // on montre la popup de sélection de services disponibles
                    this.showServicePopup = true
                    return false
                })
                .then(addProduct => {
                    if (!addProduct) {
                        return
                    }
                    return this.addProduct()
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
